import { writable } from 'svelte/store';

// export const bag = writable({ 'items':[] });

export const bag = writable(localStorage.getItem('therosyshell-bag') && tryJSON(localStorage.getItem('therosyshell-bag')) ? JSON.parse(localStorage.getItem('therosyshell-bag') ?? '{}') : { 'items': [], 'costs': { 'subtotal': 0, 'delivery': 9, 'total': 9 } });
bag.subscribe(val => localStorage.setItem('therosyshell-bag', JSON.stringify(val)) );

export const auth = writable(localStorage.getItem('therosyshell-auth') && tryJSON(localStorage.getItem('therosyshell-auth')) ? JSON.parse(localStorage.getItem('therosyshell-auth') ?? '{}') : {});
auth.subscribe(val => localStorage.setItem('therosyshell-auth', JSON.stringify(val)) );

function tryJSON (jsonString) {
    try {
        let o = JSON.parse(jsonString);
        if (o && typeof o === 'object') {
            return true;
        };
    }
    catch (e) { };

    return false;
};

export function calcPrice(product, multiply = false) {
    let price = 0, type = 'price';

    if (product.type == 'complex') {
        // Complex Product
        if (product.variant?.price) {
            // Variant
            price = product.variant.price;
        } else {
            // Range
            let prices = [...product.variations];
            let range = [];
            if (product.allowCustom == true) {
                range = [...prices.map(a => a.price), product.price]
            } else {
                range = [...prices.map(a => a.price)]
            };
            let min = Math.min(...range);
            let max = Math.max(...range);

            if (min == max) {
                // Range is 0
                type = 'price';
                price = min;
            } else {
                // Range exists
                type = 'range';
                price = `$${min.toFixed(2)} - $${max.toFixed(2)}`
            };
        }
    } else {
        // Simple Product
        type = 'price';
        price = product.price;
    };

    if (type == 'range') {
        return price;
    } else {
        if (multiply == true) return '$' + (price * product.quantity).toFixed(2);
        return '$' + price.toFixed(2);
    }
};