<script>
    import VariantOption from '../lib/VariantOption.svelte';
    import Switch from '../lib/Switch.svelte';

    export let currentRoute = {};
    import { onMount } from 'svelte';
	import { bag, calcPrice } from '../lib/data.js';
	import { getCountry } from '../lib/location.js';
    import { navigateTo } from 'svelte-router-spa';

    let product = { variant: {} }, visibleimage, addedShow = false, showVariants = true, images = [], viewerH;

    onMount(async () => {
        product = await fetch('https://db.therosyshell.co.nz/api/collections/products/records/' + currentRoute.namedParams.id + '?expand=tags,related,related.tags').then(res => { return res.json() });
        visibleimage = product.mainimage;
        product.quantity = 1;
        images = [...new Set([product.mainimage, ...product.images, ...product.variations.filter(a => a.stocked == true).map(b => b.image)])];
        await Promise.all(images.map(x => preloadImage(x)))
        // if (images.length < 1) {
        //     images = [product.mainimage];
        // };
        console.log(viewerH)
    });

    function preloadImage(src) {
        new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = resolve;
            image.onerror = reject;
            image.src = src;
        });
    };
</script>

{#if product?.name}
<div class="lg:flex-row flex flex-col items-center justify-start w-full h-full max-h-full gap-5">
    <div class="lg:flex-1 lg:h-full lg:max-h-full lg:overflow-y-auto lg:flex-col lg:w-full flex flex-row items-center justify-start max-w-full">
        <div class="shrink-0 lg:w-full lg:flex-none rounded-xl flex-1 overflow-hidden" bind:clientHeight={viewerH}>
            <img src={visibleimage} class="visibleimage block w-full h-full bg-white" alt="Product">
        </div>
        <div class="lg:grid-cols-3 lg:mt-5 lg:ml-0 lg:w-full shrink-0 lg:grid flex flex-col justify-start w-1/3 gap-5 ml-5 overflow-y-auto" style="max-height: {viewerH}px;">
            {#each images as image}
                <img src={image} class="w-full overflow-hidden bg-white cursor-pointer hover:brightness-95 shrink-0 transition-all duration-200 ease-in-out {visibleimage == image ? 'border-2 border-brand' : ''} rounded-xl" on:click={() => visibleimage = image } alt="Product Extra">
            {/each}
        </div>
    </div>
    
    <div class="lg:p-10 lg:flex-1 lg:w-auto lg:h-full rounded-xl lg:overflow-y-auto flex flex-col items-start justify-start w-full p-5 bg-white">
        <button class="hover:text-gray-500 mb-5 text-gray-700" on:click={() => navigateTo('/')}>← Back to Shop</button>

        <div class="flex flex-col items-start justify-center flex-1 w-full">
            <div class="flex flex-row flex-wrap items-center justify-start w-full gap-2 mb-5">
                {#each product['@expand']?.tags ?? [] as tag}
                    <button class="shrink-0 flex flex-row items-center justify-start px-2 py-1 border-2 rounded-full" style="border-color: {tag.color};" on:click={() => navigateTo('/?q=' + tag.name)}> 
                        <span class="p-2 mr-2 rounded-full" style="background-color: {tag.color};"></span>
                        <p>{tag.name}</p>
                    </button>
                {/each}
            </div>
    
            <div class="lg:flex-row lg:justify-between flex flex-col items-center justify-center w-full">
                <h1 class="lg:text-4xl flex-1 w-full text-3xl font-bold text-left">{product.name}</h1>
                <h1 class="lg:ml-10 lg:text-right lg:w-fit w-full text-2xl font-bold text-left">
                    <!-- {#if product.type == 'complex' && product.variant?.price}
                        {product.variant.price.toFixed(2)}
                    {:else if product.type == 'complex' && Math.min(...product.variations.map(a => a.price)) != Math.max(...product.variations.map(a => a.price))}
                        {Math.min(...product.variations.map(a => a.price ?? product.price)).toFixed(2)} - {Math.max(...product.variations.map(a => a.price ?? product.price)).toFixed(2)}
                    {:else if product.type == 'complex' && Math.min(...product.variations.map(a => a.price)) == Math.max(...product.variations.map(a => a.price))}
                        {product.variations[0].price.toFixed(2)}
                    {:else}
                        {product.price.toFixed(2)}
                    {/if} -->
                    {calcPrice(product)}
                </h1>
            </div>
    
            <div class="lg:flex-row lg:justify-between flex flex-col-reverse items-center justify-center w-full">
                <!-- {#if (product?.dimensions ?? []).length > 0}
                <div class="flex flex-row items-center justify-start mt-1">
                    <img src="/assets/img/box.svg" alt="Box" class="aspect-square w-6 mr-2">
                    <p class="text-lg">{product.dimensions.map(a => a + 'cm').join(' x ')}</p>
                </div>
                {/if} -->

                {#if product.stocked != true && product.stock < 1}
                <p class="text-lg font-semibold text-red-500">Out of Stock</p>
                {/if}
            </div>
    
            <p class="my-5 text-lg">{@html product.description.replaceAll('/BREAK/', '<br>')}</p>
    
            {#if product.type == 'complex' && product.stocked == true}
            <!-- <textarea class="h-96 w-full">{JSON.stringify(product, null, 4)}</textarea> -->
            <h1 class="lg:text-2xl w-full mb-3 text-xl font-bold text-left">Options <span class="text-gray-700 font-semibold ml-4 text-base {product.variant?.label ? 'hidden' : ''}">Click to Select</span><span class="text-gray-700 hover:text-gray-500 font-semibold ml-4 text-base cursor-pointer {product.variant?.label ? '' : 'hidden'}" on:click={() => { product.variant = undefined; showVariants = true; }}>Clear</span></h1>
            <div class="flex flex-col items-center justify-start w-full mb-3 overflow-hidden">
                {#each product.variations.filter(a => a.stocked == true) ?? [] as item}
                    <VariantOption bind:product bind:item bind:show={showVariants}>
                        <p class="w-full text-lg text-left" slot="content">{@html (item?.details ?? []).map(a => '<b>' + a.label + ':</b> ' + a.value).join(', ')}</p>
                        <!-- <div class="grid w-full grid-cols-2" slot="content">
                            {#each (item?.details ?? []) as detail}
                            <p class="w-full text-lg text-left"><b>{detail.label}:</b> {detail.value}</p>
                            {/each}
                        </div> -->
                    </VariantOption>
                {/each}

                {#if product.allowCustom == true}
                    <!-- <div class="hover:bg-gray-200 rounded-xl flex flex-row items-start overflow-hidden justify-start w-full transition-all duration-500 ease-in-out {showVariants == false && product.variant.label != 'Custom Product' ? 'h-0 -mb-2' : 'h-24'} cursor-pointer" on:click={() => { 
                        product.variant = {
                            id: 'CUSTOMPRODUCT',
                            details: [],
                            image: product.mainimage,
                            label: 'Custom Product',
                            price: product.price,
                            enabled: true
                        };
                        showVariants = false;
                    }}>
                        <img src={product.mainimage} class="aspect-square rounded-xl object-cover h-16 transition-all duration-200 ease-in-out bg-white" alt={product.name}>
                        <div class="flex flex-col items-start justify-start flex-1 h-full ml-5">
                            <p class="w-full text-lg font-bold text-left">Custom Product</p>
                            <div class="grid w-full grid-cols-2">
                                <p class="w-full text-lg text-left">Your choice of details</p>
                            </div>
                        </div>
                    </div> -->

                    <VariantOption bind:product item={{
                        id: 'CUSTOM-PRODUCT',
                        details: product?.attributes?.filter(a => a.variated != true),
                        image: product.mainimage,
                        label: 'Custom Product',
                        price: product.price,
                        stocked: true
                    }} bind:show={showVariants}>
                        <p class="w-full text-lg text-left break-words" slot="content">Your choice of colour, size, etc.<br>To discuss your choices, email us at 'contact@therosyshell.co.nz'.</p>
                    </VariantOption>
                {/if}
            </div>
            {/if}

            {#if product?.variant?.id == 'CUSTOM-PRODUCT'}
                {#each product.variant.details as attr}
                    <p class="mb-1 font-semibold">{attr.label}</p>
                    {#if attr.type == 'text'}
                        <input class="input" bind:value={attr.value} type="text" name={attr.label} />
                    {:else if attr.type == 'select'}
                        <select class="input" bind:value={attr.value} name={attr.label}>
                            {#each attr.options as option}
                                <option value={option}>{option}</option>
                            {/each}
                        </select>
                    {:else if attr.type == 'boolean'}
                        <Switch bind:value={attr.value} bool={true} />
                    {/if}
                    <span class="py-2 mb-1"></span>
                {/each}
            {/if}

            {#if getCountry() == 'New Zealand'}
            <div class="lg:flex-row {product.stocked == true && (product.stock > 0 || product?.variant?.id) && (product.type == 'simple' || product.variant?.label) ? 'h-[2.75rem]' : 'h-0'} flex transition-[height] duration-500 ease-in-out overflow-hidden mb-3 flex-col items-center justify-start w-full gap-5">
                <button class="lg:w-fit bg-brand hover:shadow-lg active:bg-brand-alt flex flex-row items-center justify-center w-full h-[2.75rem] px-10 py-2 text-lg font-bold text-white rounded-full" on:click={() => { $bag.items = [...$bag.items, JSON.parse(JSON.stringify(product))]; addedShow = true; product.variant = undefined; showVariants = true; }}><img src="/assets/img/bag.svg" alt="Bag" class="stroke-white aspect-square w-6 mr-3">Add to Bag</button>
                
                {#if product?.variant?.id == 'CUSTOM-PRODUCT' || !product.variant?.id}
                <div class="lg:justify-start lg:w-fit bg-brand flex flex-row items-center justify-center w-full h-[2.75rem] px-5 py-2 overflow-hidden text-lg text-white rounded-full select-none">
                    <p class="font-bold border-b-2 border-transparent">Qty:</p>
                    <input type="number" bind:value={product.quantity} placeholder="1" min="1" max={product.stock ?? 1} class="w-8 ml-2 bg-transparent border-b-2 border-white">
                </div>
                {/if}
            </div>
            {/if}

            <p class="text-brand font-bold overflow-hidden {addedShow == true && !product.variant?.id ? 'h-7' : 'h-0'} transition-[height] duration-500 ease-in-out">Added to Bag!</p>
            <button class="lg:w-fit bg-brand hover:shadow-lg active:bg-brand-alt flex flex-row items-center mt-1 justify-center w-full overflow-hidden transition-all duration-500 ease-in-out {addedShow == true && !product.variant?.id ? 'h-[2.75rem] py-2' : 'h-0'} px-10 text-lg font-bold text-white rounded-full" on:click={() => navigateTo('/bag')}><img src="/assets/img/bag.svg" alt="Bag" class="stroke-white aspect-square w-6 mr-3">View my Bag</button>
            <button class="lg:w-fit bg-brand hover:shadow-lg active:bg-brand-alt flex flex-row items-center mt-3 justify-center w-full overflow-hidden transition-all duration-500 ease-in-out {addedShow == true && !product.variant?.id ? 'h-[2.75rem] py-2' : 'h-0'} px-10 text-lg font-bold text-white rounded-full" on:click={() => navigateTo('/')}><img src="/assets/img/search.svg" alt="Search" class="stroke-white invert aspect-square w-6 mr-3">Continue Shopping</button>
        </div>

        {#if product.related?.length > 0}
        <div class="flex flex-col items-center justify-end w-full mt-5">
            <h1 class="lg:text-2xl flex-1 w-full text-xl font-bold text-left">Related Products</h1>

            <!-- <div class="grid w-full grid-cols-3 gap-5"> -->
                {#each product['@expand'].related as item}
                <div class="group flex flex-row items-center justify-start w-full h-32 cursor-pointer" on:click={() => navigateTo('/product/' + item.id)}>
                    <img src={item.mainimage} class="drop-shadow-md aspect-square rounded-xl group-hover:drop-shadow-none object-cover h-full transition-all duration-200 ease-in-out bg-white" alt={item.name}>
                    <div class="flex flex-col items-start justify-center flex-1 h-full ml-5">
                        <p class="w-full text-lg font-bold text-left">{item.name}</p>
                        <p class="w-full mb-2 text-left">${item.price.toFixed(2)}</p>
                        
                        {#each item['@expand'].tags as tag}
                        <div class="shrink-0 flex flex-row items-center justify-start px-2 py-1 border-2 rounded-full" style="border-color: {tag.color};">
                            <span class="p-2 mr-2 rounded-full" style="background-color: {tag.color};"></span>
                            <p class="text-sm">{tag.name}</p>
                        </div>
                        {/each}
                    </div>
                </div>

                <!-- <div class="rounded-xl group w-full overflow-hidden bg-white cursor-pointer select-none" on:click={() => navigateTo(`/product/${item.id}`)}>
                    <img src={item.mainimage} class="aspect-square group-hover:shadow-none rounded-xl object-cover w-full transition-shadow duration-200 ease-in-out bg-white shadow-md" alt={item.name}>
                    <p class="w-full px-3 py-3 text-center">{item.name}</p>
                </div> -->
                {/each}
            <!-- </div> -->
        </div>
        {/if}
    </div>
</div>
{/if}

<style>
    .input {
        @apply p-3 rounded-xl overflow-hidden bg-slate-200 transition-shadow duration-200 ease-in-out w-full mb-3 placeholder:text-gray-600;
    }
    .input:focus {
        @apply ring-2 ring-pink-300;
    }
</style>