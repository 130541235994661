<script>
    import Bag from '../lib/Bag.svelte';
    import Checkout from '../lib/Checkout.svelte';
</script>
<div class="flex flex-col items-center justify-start w-full gap-5 lg:h-screen lg:max-h-full lg:overflow-hidden lg:justify-center lg:flex-row">
    <Bag></Bag>
    <span class="lg:hidden py-5 px-1"></span>
    <Checkout></Checkout>
</div>

<style>

</style>