<script>
    import { onMount } from 'svelte';
	import { bag, calcPrice } from './data.js';
    import { navigateTo } from 'svelte-router-spa';

    onMount(async () => {
        getSubtotal();
    });

    $: getSubtotal($bag.items);

    let oldbag = [];
    async function getSubtotal() {
        if (JSON.stringify(oldbag) != JSON.stringify($bag.items)) {
            oldbag = JSON.parse(JSON.stringify($bag.items));
            let calc = await fetch('https://server.therosyshell.co.nz/checkout/calc', {
            // let calc = await fetch('http://192.168.1.19:5229/checkout/calc', {
                method: 'POST',
                headers: {
                    'Authorization': 'Ymjb58aay9tKUQhoYg43vgpm9iLg8dV7',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify($bag)
            }).then(res => { return res.json(); });

            $bag.costs.subtotal = calc.subtotal;
        };
    };

    $: $bag.costs.total = ($bag.costs.subtotal ?? 0) + ($bag.costs.delivery ?? 0);
</script>

<div class="lg:w-2/5 rounded-xl flex flex-col items-center justify-start w-full h-full max-h-full">
    <div class="bg-brand lg:rounded-b-none lg:mb-0 flex flex-col items-center justify-center w-full p-3 mb-2 text-white rounded-lg">
        <h1 class="font-brand text-4xl">Your Bag</h1>
    </div>
    
    <div class="rounded-b-xl lg:flex flex-row items-center justify-center hidden w-full p-2 mb-2 font-bold bg-white">
        <h1 class="invisible w-16 mr-3 text-xl">Img</h1>
        <h1 class="flex-1 text-xl">Item</h1>
        <h1 class="w-1/4 text-xl">Price</h1>
        <p class="invisible px-2 text-xl">✕</p>
    </div>
    
    <div class="lg:max-h-full lg:overflow-auto flex flex-col items-center justify-start flex-1 w-full gap-2" id="items">
        {#each $bag.items as item, i}
            <div class="rounded-xl flex flex-col items-center justify-center w-full p-2 bg-white" data-search="{item.name} {item.id} {item.tags.join(' ')}">
                <div class="flex flex-row items-center justify-center w-full mb-1">
                    <img src={item.variant?.image ?? item.mainimage} class="w-16 mr-3" alt={item.name}>
                    <div class="flex flex-col items-start justify-center flex-1" on:click={() => navigateTo(`/product/${item.id}`)}>
                        <h1 class="text-lg">{item.name}</h1>
                        <h1 class="text-lg"><b>Qty: </b>{item.quantity}</h1>
                    </div>
                    <div class="lg:flex flex-col items-start justify-center hidden w-1/4">
                        <h1 class="text-lg">{calcPrice(item)} ea.</h1>
                        <h1 class="text-lg font-bold">{calcPrice(item, true)}</h1>
                    </div>
                    <p class="px-2 text-xl cursor-pointer" on:click={() => { $bag.items.splice(i, 1); $bag.items = $bag.items; }}>✕</p>
                </div>

                {#if item.variant}
                <p class="w-full text-lg font-bold text-left">{item.variant.label}</p>
                <p class="w-full text-lg text-left">{@html (item.variant?.details ?? []).map(a => '<b>' + a.label + ':</b> ' + a.value).join(', ')}</p>
                <!-- <div class="grid w-full grid-cols-2">
                    {#each (item.variant?.details ?? []) as detail}
                    <p class="w-full text-lg text-left"><b>{detail.label}:</b> {detail.value}</p>
                    {/each}
                </div> -->
                {/if}

                <div class="lg:hidden flex flex-row items-start justify-between w-full">
                    <h1 class="text-lg">{calcPrice(item)} ea.</h1>
                    <h1 class="text-lg font-bold">{calcPrice(item, true)}</h1>
                </div>
            </div>
        {/each}
    </div>
    
    <div class="rounded-xl flex flex-row items-center justify-center w-full p-5 mt-5 bg-white">
        {#if $bag?.costs}
        <table class="w-full">
            <tr>
                <td class="font-bold">Subtotal</td>
                <td class="text-right">${($bag.costs?.subtotal ?? 0).toFixed(2)}</td>
            </tr>
            <tr>
                <td class="font-bold">Delivery</td>
                <td class="text-right">${($bag.costs?.delivery ?? 0).toFixed(2)}</td>
            </tr>
            <tr>
                <td class="font-bold">Total</td>
                <td class="font-bold text-right">${($bag.costs?.total ?? 0).toFixed(2)}</td>
            </tr>
        </table>
        {/if}
    </div>
</div>

<style>

</style>

