// https://github.com/jorgegorka/svelte-router/blob/master/README.md
import { getCountry } from './lib/location.js';

import Home from './routes/Home.svelte';
import BagCheckout from './routes/BagCheckout.svelte';
import Info from './routes/Info.svelte';
import Account from './routes/Account.svelte';
import LoginSignup from './routes/LoginSignup.svelte';
import Product from './routes/Product.svelte';
import NotFound from './routes/NotFound.svelte';

const routes = [
    {
        name: '/',
        component: Home,
    },
    {
        name: '/bag',
        component: BagCheckout,
        onlyIf: { guard: () => !(getCountry() != 'New Zealand'), redirect: '/' },
    },
    {
        name: '/info',
        component: Info,
    },
    {
        name: '/account',
        component: Account,
        onlyIf: { guard: () => { if (window.localStorage.getItem('therosyshell-auth') && JSON.parse(window.localStorage.getItem('therosyshell-auth')).token) return true; }, redirect: '/login' },
    },
    {
        name: '/login',
        component: LoginSignup,
    },
    {
        name: '/product/:id',
        component: Product,
    },
    {
        name: '404',
        component: NotFound
    }
]

export { routes }