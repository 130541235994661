<script>
    import { onMount } from 'svelte';
    import { navigateTo } from 'svelte-router-spa';
	import { auth, calcPrice } from '../lib/data.js';
    import dayjs from 'dayjs';
    import AddressForm from '../lib/AddressForm.svelte';

    let orders = [], showSaveConfirm = false;
    onMount(async () => {
        let orderlist = await fetch('https://db.therosyshell.co.nz/api/collections/orders/records', {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'User ' + $auth.token
            }
        }).then(res => { return res.json() });
        orders = orderlist.items;
    });

    function sleep(s) {
        return new Promise(resolve => setTimeout(resolve, (s * 1000)));
    };
</script>

<div class="lg:h-screen lg:max-h-full lg:overflow-hidden lg:flex-row lg:justify-center flex flex-col items-center justify-start w-full gap-5">
    <div class="lg:flex-1 lg:h-full lg:max-h-full lg:overflow-auto rounded-xl flex flex-col items-center justify-start w-full">
        <div class="bg-brand banner flex flex-col items-center justify-center w-full p-3 text-white rounded-lg">
            <h1 class="font-brand text-4xl text-center">My Account</h1>
        </div>

        <div class="rounded-xl flex flex-col items-center justify-center w-full p-5 mt-5 bg-white">
            <h1 class="text-center">Welcome Back, {$auth.profile.first}!</h1>
            <h2>You're logged in with {$auth.email}</h2>

            <button class="rounded-xl bg-brand w-full px-5 py-2 mt-3 text-white" on:click={() => { $auth = {}; window.location = '/login'; }}>Sign Out</button>
        </div>

        <div class="rounded-xl flex flex-col items-center justify-center w-full p-5 mt-5 bg-white">
            <h1 class="text-center">My Account Details</h1>

            <!-- <p class="w-full mb-1 text-left">Email Address</p>
            <input placeholder="john.doe@email.tld" bind:value={$auth.email}> -->
            
            <div class="gap-x-5 grid w-full grid-cols-2">
                <p class="w-full mb-1 text-left">First Name</p>
                <p class="w-full mb-1 text-left">Last Name</p>

                <input placeholder="John" bind:value={$auth.profile.first}>
                <input placeholder="Doe" bind:value={$auth.profile.last}>
            </div>

            <p class="w-full mb-1 text-left">Phone Number</p>
            <input type="number" placeholder="0123456789" min="0" bind:value={$auth.profile.phone}>

            <AddressForm bind:value={$auth.profile.address}></AddressForm>

            {#if showSaveConfirm == true}
                <p class="text-brand w-full pt-1 font-bold text-center">Successfully Saved!</p>
            {/if}
            <button class="rounded-xl bg-brand w-full px-5 py-2 mt-3 text-white" on:click={async () => { await fetch('https://db.therosyshell.co.nz/api/collections/profiles/records/' + $auth.profile.id, { method: 'PATCH', headers: { 'Authorization': 'User ' + $auth.token, 'Content-Type': 'application/json' }, body: JSON.stringify({ first: $auth.profile.first, last: $auth.profile.last, address: $auth.profile.address, phone: $auth.profile.phone }) }); showSaveConfirm = true; await sleep(5); showSaveConfirm = false; }}>Save</button>
        </div>
    </div>

    <div class="lg:flex-1 lg:h-full lg:max-h-full lg:overflow-auto rounded-xl flex flex-col items-center justify-start w-full">
        <div class="bg-brand banner flex flex-col items-center justify-center w-full p-3 text-white rounded-lg">
            <h1 class="font-brand text-4xl text-center">My Orders</h1>
        </div>

        {#each orders.sort((a, b) => a.created < b.created ? 1 : -1) as order}
            <div class="rounded-xl flex flex-col items-center justify-center w-full p-5 mt-5 {order.payment.value != 'awaiting' && (order.fulfilment.value != 'ordered' && order.fulfilment.value != 'inprogress') ? 'bg-gray-200' : 'bg-white'}">
                <div class="flex flex-row items-center justify-center w-full">
                    <h1 class="text-left">[{order.nanoid}]</h1>
                    <h1 class="text-right">${order.costs.total.toFixed(2)}</h1>
                </div>
                <p class="w-full mb-2 text-lg text-left">{dayjs.unix(order.orderedDate).format('dddd, D MMMM YYYY - hh:mm a')}</p>
                <div class="status flex flex-row items-center justify-center w-full">
                    <p class="flex-1 text-lg text-left"><b>Payment:</b> {order.payment.show}</p>
                    <p class="flex-1 text-lg text-right"><b>Shipping:</b> {order.fulfilment.show}</p>
                </div>
                <div class="status flex flex-row items-center justify-center w-full">
                    <p class="flex-1 text-lg text-left">{order.billing.option.label}</p>
                    <p class="flex-1 text-lg text-right">{order.shipping.mode.value == 'deliver' ? order.shipping.option.label : order.shipping.mode.label}</p>
                </div>
                {#if order.tracking?.no}
                <a class="w-full mt-2 text-lg text-left" href={order.tracking.url + order.tracking.no} target="_blank"><b>Tracking Number: </b>{order.tracking.no}</a>
                {/if}

                <div class="itemslist flex flex-col items-center justify-start w-full mt-3">
                    {#each order.items as item, i}
                        <div class="flex flex-col items-center justify-center w-full py-3 border-t border-gray-300" data-search="{item.name} {item.id} {item.tags.join(' ')}">
                            <div class="flex flex-row items-center justify-center w-full mb-1">
                                <img src={item.variant?.image ?? item.mainimage} class="aspect-square object-cover w-16 mr-3" alt={item.name}>
                                <button class="flex flex-col items-start justify-center flex-1" on:click={() => navigateTo(`/product/${item.id}`)}>
                                    <h1 class="text-lg">{item.name}</h1>
                                    <h1 class="text-lg"><b>Qty: </b>{item.quantity}</h1>
                                </button>
                                <div class="flex flex-col items-start justify-center w-1/4">
                                    <h1 class="text-lg">{calcPrice(item)} ea.</h1>
                                    <h1 class="text-lg font-bold">{calcPrice(item, true)}</h1>
                                </div>
                            </div>

                            {#if item.variant}
                            <p class="w-full text-lg font-bold text-left">{item.variant.label}</p>
                            <p class="w-full text-lg text-left">{@html (item.variant?.details ?? []).map(a => '<b>' + a.label + ':</b> ' + a.value).join(', ')}</p>
                            <!-- <div class="grid w-full grid-cols-3">
                                {#each (item.variant?.details ?? []) as detail}
                                <p class="w-full text-lg text-left">{detail.label}: {detail.value}</p>
                                {/each}
                            </div> -->
                            {/if}
                        </div>
                    {/each}
                </div>
            </div>
        {/each}
    </div>
</div>

<style>
    h1:not(.banner h1, .itemslist h1) {
        @apply w-full mb-2 text-2xl;
    }
    
    h2:not(.itemslist h2) {
        @apply w-full text-xl text-center;
    }
    
    a:not(.bg-brand, .itemslist a) {
        @apply w-full mb-1 text-brand underline;
    }

    p.line {
        width: 100%; 
        text-align: center; 
        border-bottom: 1px solid #000; 
        line-height: 0.1em;
        margin: 10px 0 20px; 
    } 

    p.line span { 
        background:#fff; 
        padding: 0 10px; 
    }

    input {
        @apply p-3 rounded-xl overflow-hidden bg-slate-200 transition-shadow duration-200 ease-in-out w-full mb-3 placeholder:text-gray-600;
    }

    input:focus, input:active {
        @apply ring-2 ring-pink-300;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>