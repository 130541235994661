<script>
    // import { Splide, SplideSlide } from '@splidejs/svelte-splide';
    // import '@splidejs/svelte-splide/css';
    import { onMount } from 'svelte';
    import { navigateTo } from 'svelte-router-spa';
	import { calcPrice } from '../lib/data.js';

    let winParams = new URLSearchParams(window.location.search), products = [], tags = [], searchQuery = '';

    onMount(async () => {
        let urls = [
            'https://db.therosyshell.co.nz/api/collections/products/records?expand=tags',
            'https://db.therosyshell.co.nz/api/collections/tags/records'
        ]
        let data = await Promise.all(urls.map(async url => {
            const res = await fetch(url);
            return res.json();
        }));
        products = data[0].items;
        tags = data[1].items;

        console.log(winParams.get('q'))
        searchQuery = winParams.get('q');
        search();
    });

    function search() {
        let input = (searchQuery ?? '').toUpperCase(),
            list = document.querySelector('#products').querySelectorAll('div');
            console.log(list)

        for (var i = 0; i < list.length; i++) {
            let content = list[i].getAttribute('data-search');
            if (content) {
                if (content.toUpperCase().indexOf(input) > -1) {
                    list[i].style.display = '';
                } else {
                    list[i].style.display = 'none';
                }
            }
        }
    };
    // $: if (searchQuery != winParams.get('q')) {
    //     winParams.set('q', '');
    //     window.location.search = winParams.toString();
    // };
</script>

<!-- <div class="p-5 mb-5 font-bold text-white bg-red-300 rounded-md">
    <a href="https://lightondev.xyz" class="text-xl underline">Light On Dev</a>
    <p>This is a Local Development Environment. All content is subject to change.</p>
    <p>Public URL: <code class="font-code font-normal">http://ca14-2405-da40-1182-4500-7ca5-4a6e-3d25-c63b.ngrok.io/</code></p>
</div> -->

<!-- <div class="rounded-xl mb-5 overflow-hidden">
    <Splide options={{ 'type': 'loop', 'autoplay': true, 'arrows': false }}>
        {#each sliders as slider}
            <SplideSlide>
                <img src={slider.image} class="w-full" alt="{slider.alt} Slider">
            </SplideSlide>
        {/each}
    </Splide>
</div> -->

<div class="lg:flex bg-brand flex-row items-center justify-center hidden w-full text-white rounded-t-lg">
    <h1 class="font-brand p-5 text-6xl">The Rosy Shell</h1>
</div>
<div class="lg:rounded-t-none lg:flex-nowrap shrink-0 flex flex-row flex-wrap items-center justify-center w-full overflow-hidden bg-white rounded-lg">
    {#each tags.filter(a => a.featured == true).sort((a, b) => a.name > b.name ? 1 : -1) as tag}
        <button on:click={() => { searchQuery = tag.name; search(); }} class="lg:text-lg hover:bg-pink-200 flex-1 min-h-[2.75rem] px-4 py-2 transition-colors duration-200 ease-in-out">{tag.name}</button>
    {/each}
</div>

<div class="rounded-xl flex flex-row items-center justify-center w-full my-5 bg-white">
    <img src="/assets/img/search.svg" alt="Search" class="aspect-square w-6 mx-3">
    <input class="flex-1 py-3 pr-3 text-lg bg-transparent" bind:value={searchQuery} on:keyup={() => search()} on:contextmenu|preventDefault={() => { searchQuery = ''; search(); }}>
    <img src="/assets/img/close.svg" alt="Clear" class="aspect-square w-8 mx-3 cursor-pointer" class:hidden={(searchQuery ?? '').length < 1} on:click={() => { searchQuery = ''; search(); }}>
</div>
<div class="lg:grid-cols-4 2xl:grid-cols-5 grid w-full grid-cols-2 gap-5 mb-5 overflow-visible" id="products">
    {#each products.filter(a => a.enabled == true).sort((a, b) => a.name > b.name ? 1 : -1) as product}
        <div class="rounded-xl group flex flex-col items-center justify-between w-full overflow-hidden bg-white cursor-pointer select-none" on:click={() => navigateTo(`/product/${product.id}`)} data-search="{product.name} {product.id} {product['@expand']?.tags.map(a => a.name).join(' ')}">
            <img src={product.mainimage} class="aspect-square group-hover:shadow-none group-hover:rounded-none rounded-xl object-cover w-full transition-all duration-200 ease-in-out bg-white shadow-md" alt={product.name}>
            <div class="group-hover:bg-pink-100 flex flex-col items-center justify-center flex-1 w-full px-3 py-3 text-xl text-center">
                <p class="font-semibold">{product.name}</p>
                <p>{calcPrice(product)}</p>
            </div>
        </div>
    {/each}
</div>

<style>

</style>