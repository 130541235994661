<script>
    export let item = {}, product = {}, show = true, inbag = false;

	import { bag } from './data.js';
    $: inbag = $bag.items.map(a => a.variant?.id ?? 'NON-VARIANT').filter(b => b != 'CUSTOM-PRODUCT' && b != 'NON-VARIANT').includes(item.id);

    let contentWrapper = { scrollHeight: 0 };
</script>

<div class="hover:bg-gray-200 rounded-xl {product.variant?.label == item.label ? 'bg-slate-200' : ''} {inbag == true ? 'text-gray-500 cursor-not-allowed' : 'text-black cursor-pointer'} flex flex-row items-start overflow-hidden justify-start w-full transition-all duration-500 ease-in-out select-none" style="{show == false && product.variant.label != item.label ? 'max-height: 0px; padding-top: 0px; padding-bottom: 0px;' : 'max-height: ' + (contentWrapper.scrollHeight + 24) + 'px; padding-top: 0.75rem; padding-bottom: 0.75rem; margin-bottom: 0.5rem;'}" on:click={() => { if (inbag != true) { product.variant = item; show = false; } }}>
    <img src={item.image} class="aspect-square rounded-xl object-cover h-16 ml-3 transition-all duration-200 ease-in-out bg-white" alt={item.name}>
    <div class="flex flex-col items-start justify-center flex-1 h-full ml-5 min-h-[4rem]" bind:this={contentWrapper}>
        <p class="w-full text-lg font-bold text-left">{item.label} - {item?.id == 'CUSTOM-PRODUCT' ? 'From ' : ''}${item.price.toFixed(2)}{inbag == true ? ' - Already in bag' : ''}</p>
        <slot name="content"></slot>
    </div>
</div>
