<script>
	import { auth } from '../lib/data.js';

    let btn, email = '', password = '', name = { 'first': '', 'last': '' }, err = { show: false, message: '' };

    async function signUp() {
        btn.disabled = true;

        if (email.length < 1 || password.length < 5) {
            err = { show: true, message: 'Invalid Email or Password' };
            return btn.disabled = false;
        } else if (name.first.length < 1 || name.last.length < 1) {
            err = { show: true, message: 'Invalid First or Last Name' };
            return btn.disabled = false;
        } else if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email) != true) {
            err = { show: true, message: 'Invalid Email' };
            return btn.disabled = false;
        } else {
            err.show = false;
        };
        
        try {
            let create = await fetch('https://db.therosyshell.co.nz/api/users', {
                'method': 'POST',
                'headers': {
                    'Content-Type': 'application/json'
                },
                'body': JSON.stringify({
                    "email": email,
                    "password": password,
                    "passwordConfirm": password,
                })
            }).then(res => { return res.json() });
            
            if (!create.id) throw create;
        } catch (error) {
            err = { show: true, message: error.message };
            return btn.disabled = false;
        };

        let user = await fetch('https://db.therosyshell.co.nz/api/users/auth-via-email', {
            'method': 'POST',
            'headers': {
                'Content-Type': 'application/json'
            },
            'body': JSON.stringify({
                "email": email,
                "password": password
            })
        }).then(res => { return res.json() });

        let profile = await fetch('https://db.therosyshell.co.nz/api/collections/profiles/records/' + user.user.profile.id, {
            'method': 'PATCH',
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'User ' + user.token
            },
            'body': JSON.stringify({
                "first": name.first,
                "last": name.last,
                "address": {
                    line1: '',
                    line2: '',
                    city: '',
                    region: '',
                    country: '',
                    postcode: ''
                }
            })
        }).then(res => { return res.json() });

        $auth = user.user;
        $auth.profile = profile;
        $auth.token = user.token;

        email = '', password = '', name = {'first': '', 'last': ''};
    };
</script>

<div class="gap-x-5 grid w-full grid-cols-2">
    <p>First Name</p>
    <p>Last Name</p>
    <input placeholder="John" bind:value={name.first}>
    <input placeholder="Doe" bind:value={name.last}>
</div>
<p>Email Address</p>
<input placeholder="john.doe@email.tld" bind:value={email} name="email" on:keydown={e => { if (e.key == 'Enter') signUp(); }}>
<p>Password</p>
<input placeholder="*********" type="password" bind:value={password} name="password" on:keydown={e => { if (e.key == 'Enter') signUp(); }}>
<button class="rounded-xl bg-brand w-full px-5 py-2 text-white" on:click={() => signUp()} bind:this={btn}>Sign Up</button>
{#if err.show == true}
<p class="text-brand w-full pt-1 font-bold text-center">{err.message}</p>
{/if}

<style>
    p {
        @apply w-full text-left mb-1;
    }
    input {
        @apply p-3 rounded-xl overflow-hidden bg-slate-200 transition-shadow duration-200 ease-in-out w-full mb-3 placeholder:text-gray-600;
    }
    input:focus, input:active {
        @apply ring-2 ring-pink-300;
    }
</style>

