<script>
    import SignupForm from '../lib/SignupForm.svelte';
    import LoginForm from '../lib/LoginForm.svelte';

    import { navigateTo } from 'svelte-router-spa';
	import { auth } from '../lib/data.js';

    let mode = 'In';

    $: if ($auth?.token) {
        navigateTo('/account');
    }
</script>

<div class="lg:flex-row lg:max-h-full lg:overflow-hidden flex flex-col items-center justify-center w-full lg:h-full flex-1 gap-5">
    <div class="rounded-xl flex flex-col items-center justify-center w-full lg:h-full flex-1 p-5 bg-white">
        <h1 class="text-6xl text-center">Welcome to<br><span class="text-brand">The Rosy Shell</span>!</h1>
        <h1 class="mt-3 text-3xl text-center">To get started, please Sign {mode}.</h1>
        {#if mode == 'In'}
        <button class="bg-brand rounded-xl w-full px-5 py-2 mt-5 text-white" on:click={() => mode = 'Up'}>Don't have an account yet? Sign Up!</button>
        {:else}
        <button class="bg-brand rounded-xl w-full px-5 py-2 mt-5 text-white" on:click={() => mode = 'In'}>Already have an account? Log In!</button>
        {/if}
    </div>
    {#if mode == 'In'}
    <div class="rounded-xl flex flex-col items-center justify-center w-full lg:h-full flex-1 p-5 bg-white">
        <LoginForm></LoginForm>
    </div>
    {:else}
    <div class="rounded-xl flex flex-col items-center justify-center w-full lg:h-full flex-1 p-5 bg-white">
        <SignupForm></SignupForm>
    </div>
    {/if}
</div>

<style>
    p {
        @apply w-full text-left mb-1;
    }
    a {
        @apply w-full mb-1 text-brand underline;
    }
    input {
        @apply p-3 rounded-xl overflow-hidden bg-slate-200 transition-shadow duration-200 ease-in-out w-full mb-3 placeholder:text-gray-600;
    }
    input:focus, input:active {
        @apply ring-2 ring-pink-300;
    }
</style>