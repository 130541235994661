<script>
    
</script>

<div id="banner" class="bg-brand flex flex-col items-center justify-center w-full p-3 mb-5 text-white rounded-lg">
    <h1 class="font-brand text-4xl">Info, Terms and Conditions</h1>
</div>

<div class="lg:overflow-hidden lg:flex-row flex flex-col items-center justify-center flex-1 w-full gap-5">
    <div class="rounded-xl flex flex-col items-center justify-start flex-1 h-full p-5 overflow-y-auto bg-white">
        <h1>Terms & Conditions</h1>
        <p>All of our products are individually homemade, and not factory manufactured. As a result, items will have subtle differences from one product to the next, caused by variations in the fabric as well as the hand crafting.</p>
        <p>The images, descriptions and measurements you will find on our site are as accurate as possible, but discrepancies may occur.</p>
        <h2>Returns Policy</h2>
        <p>Goods bought cannot be returned or exchanged unless faulty. All purchases are final. If you cancel your order, an admin fee of 3% of the sales total (incl postage) will be deducted from your payment before refund. No refunds are available on made to order products once processing has started.</p>
        <h2 class="mt-5">Privacy Policy</h2>
        <p>We promise not to share your personal data with anyone.</p>
        <h2 class="mt-5">Payment</h2>
        <p>Payment is accepted via online bank transfer, or by debit/credit card through Stripe. Payment must be completed before the order will be processed.</p>
    </div>

    <div class="rounded-xl flex flex-col items-center justify-start flex-1 h-full p-5 overflow-y-auto bg-white">
        <h1>Shipping</h1>
        <h2>Details</h2>
        <p>Shipping is by tracked courier.</p>
        <p>Many of our items are made to order and we will endeavor to ship them to you within 14 days of payment being received. At busy times, this may take longer but we will let you know in advance. If your item is in stock, it will be shipped within 3 working days of payment being finalised.</p>
        <h2 class="mt-5">Prices</h2>
        <p>Shipping to Urban Addresses is $9</p>
        <p>Shipping to Rural Addresses is $14</p>
        <p>We do offer a discount on delivery if you live on the Hibiscus Coast. Delivery to the Hibiscus Coast is $4</p>
    </div>
</div>

<style>
    h1:not(#banner h1) {
        @apply w-full mb-2 text-3xl text-center;
    }
    
    h2 {
        @apply w-full text-2xl text-center mt-5;
    }
    
    p {
        @apply w-full text-lg text-center;
    }
</style>