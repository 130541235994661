<script>
    export let drawer = false, drawerShow;
	import { bag } from './data.js';
	import { getCountry } from './location.js';
    import { navigateTo } from 'svelte-router-spa';
</script>

<div class="z-0 flex-col items-center justify-center {drawer == 'true' ? 'w-full flex' : 'w-1/5 lg:flex hidden'} h-full max-h-full relative p-10 overflow-x-hidden overflow-y-scroll bg-white shrink-0 overscroll-none">
    {#if drawer == 'true'}
    <button class="absolute top-0 left-0 m-5 z-[101]" on:click={() => drawerShow = false}><img src="/assets/img/close.svg" class="h-16 p-2 aspect-square" alt="Close Menu Icon"></button>
    {/if}

    <img src="/favicon.png" class="w-32 rounded-full aspect-square cursor-pointer" alt="The Rosy Shell Logo" on:click={() => { navigateTo('/'); drawerShow = false; }} />
    <h1 class="w-full pb-8 text-4xl text-center border-b border-gray-300 font-brand cursor-pointer" on:click={() => { navigateTo('/'); drawerShow = false; }}>The Rosy Shell</h1>

    <button on:click={() => { navigateTo('/'); drawerShow = false; }} class="btn">Shop</button>
    {#if getCountry() == 'New Zealand'}
    <button on:click={() => { navigateTo('/bag'); drawerShow = false; }} class="btn">Bag ({$bag.items.map(a => a.quantity).reduce((b, c) => b + c, 0)})</button>
    {/if}
    <button on:click={() => { navigateTo('/info'); drawerShow = false; }} class="btn">Info</button>
    <button on:click={() => { navigateTo('/account'); drawerShow = false; }} class="btn">My Account</button>
    <!-- <button on:click={() => { navigateTo('/contact'); drawerShow = false; }} class="btn">Contact</button> -->
    <!-- <button on:click={() => { navigateTo('/classes'); drawerShow = false; }} class="btn">Classes</button> -->
    <!-- <button on:click={() => { navigateTo('/markets'); drawerShow = false; }} class="btn">Markets</button> -->
    <!-- <button on:click={() => { navigateTo('/blog'); drawerShow = false; }} class="btn">Blog</button> -->

    <!-- <a class="mt-8 text-lg text-brand" on:click={() => { navigateTo('/bag'); drawerShow = false; }}>{$bag.items.map(a => a.quantity).reduce((b, c) => b + c, 0)} Items in your Bag</a> -->

    <div class="flex flex-row items-center w-full my-8 justify-evenly">
        <a href="https://www.facebook.com/therosyshell/"><img src="/assets/img/fb.png" alt="Facebook" class="w-6 aspect-square"></a>
        <a href="https://www.instagram.com/therosyshell/"><img src="/assets/img/ig.svg" alt="Instagram" class="w-6 aspect-square"></a>
        <a href="https://m.me/489435091552918/"><img src="/assets/img/msg.png" alt="Messenger" class="w-6 aspect-square"></a>
    </div>

    <button class="text-lg text-center text-brand" on:click={() => { navigateTo('/'); drawerShow = false; }}>&copy; The Rosy Shell 2022</button>
    <a class="text-lg text-center text-[#4392f1]" href="https://lightondev.com/">Built by Light On Dev</a>
</div>

<style>
    .btn {
        @apply p-3 text-xl text-center w-full border-b border-gray-300;
        transition: 0.35s;
        position: relative;
        overflow: hidden;
    }
    .btn::after {
        @apply bg-brand;
        top: 0;
        bottom: 0;
        left: -100%;
        right: 100%;
        content: "";
        position: absolute;
        z-index: -1;
        padding: 0.85em 0.75em;
        display: block;
        transition: all 0.35s;
    }
    .btn:hover {
        color: white;
    }
    .btn:hover::after {
        left: 0;
        right: 0;
    }
</style>
