<script>
    export let options = [], value = '';
</script>

{#each options as option, i}
{#if option.show == true}
<div class="flex flex-row items-center justify-center select-none cursor-pointer p-5 w-full {value.value == option.value ? 'border-brand border-4 bg-pink-100' : 'border-gray-200 border-2 bg-transparent'} {i == 0 ? 'rounded-t-xl' : (i == options.length - 1 ? 'rounded-b-xl' : '')}" on:click={() => value = option}>
    <img src={option.icon} alt={option.label} class="aspect-square w-8 mr-3">
    <p class="flex-1 text-xl text-left">{option.label}</p>
</div>
{/if}
{/each}

<style>

</style>
