<svelte:head>
    <script src="/assets/country-region.js"></script>
</svelte:head>

<script>
    export let value = {
        // careof: '',
        line1: '',
        line2: '',
        city: '',
        region: '',
        country: 'New Zealand',
        postcode: ''
    };
</script>

<!-- <p>C/O</p>
<input name="careof" placeholder="John Doe" bind:value={value.careof}/> -->

<p>Address Line 1</p>
<input name="address" placeholder="123 Main Street" autocomplete="address-line1" bind:value={value.line1}/>

<p>Address Line 2 (Optional)</p>
<input name="apartment" placeholder="Apartment 5b" autocomplete="address-line2" bind:value={value.line2}/>

<div class="lg:grid gap-x-5 hidden w-full grid-cols-2">
    <p>City</p>
    <p>Region</p>

    <input name="city" placeholder="Metropolis" autocomplete="address-level2" bind:value={value.city}/>
    <input name="region" placeholder="Auckland" autocomplete="address-region" bind:value={value.region}/>
    <!-- <select id="crs-region" name="region" bind:value={value.region}/> -->
</div>
<div class="lg:hidden block w-full">
    <p>City</p>
    <input name="city" placeholder="Metropolis" autocomplete="address-level2" bind:value={value.city}/>
    
    <p>Region</p>
    <input name="region" placeholder="Auckland" autocomplete="address-region" bind:value={value.region}/>
    <!-- <select id="crs-region" name="region" bind:value={value.region}/> -->
</div>


<div class="lg:grid gap-x-5 hidden w-full grid-cols-2">
    <p>Country</p>
    <p>Post Code</p>

    <input name="country" placeholder="New Zealand" autocomplete="address-country" bind:value={value.country}/>
    <!-- <select name="country" class="crs-country" data-region-id="crs-region" bind:value={value.country}/> -->
    <input name="postcode" placeholder="12345" autocomplete="postal-code" bind:value={value.postcode}/>
</div>
<div class="lg:hidden block w-full">
    <p>Country</p>
    <input name="country" placeholder="New Zealand" autocomplete="address-country" bind:value={value.country}/>
    <!-- <select name="country" class="crs-country" data-region-id="crs-region" bind:value={value.country}/> -->
    
    <p>Post Code</p>
    <input name="postcode" placeholder="12345" autocomplete="postal-code" bind:value={value.postcode}/>
</div>

<style>
    p {
        @apply w-full text-left mb-1;
    }

    input, select {
        @apply p-3 rounded-xl overflow-hidden bg-slate-200 transition-shadow duration-200 ease-in-out w-full mb-3 placeholder:text-gray-600;
    }

    input:focus,
    input:active {
        @apply ring-2 ring-pink-300;
    }
</style>