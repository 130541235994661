<script>
	import { bag, auth } from './data.js';
    import SignupForm from './SignupForm.svelte';
    import LoginForm from './LoginForm.svelte';
    import MultipleChoice from './MultipleChoice.svelte';
    import AddressForm from './AddressForm.svelte';
    import dayjs from 'dayjs';
    import { navigateTo } from 'svelte-router-spa';

    import { customAlphabet } from 'nanoid';
    const nanoid = customAlphabet('6789BCDFGHJKLMNPQRTWbcdfghjkmnpqrtwz', 9);

    let progress = 0, order = {
        nanoid: 0,
        items: $bag.items,
        payment: { value: 'awaiting', show: 'Awaiting' },
        fulfilment: { value: 'ordering', show: 'Ordering' },
        shipping: {
            address: {
                line1: '',
                line2: '',
                city: '',
                region: '',
                country: 'New Zealand',
                postcode: ''
            },
            // mode: {'label': 'Deliver to my Address', 'value': 'deliver', 'icon': '/assets/img/delivery.svg'},
            // option: {'label': 'Urban Delivery - $9', 'value': 'urban', 'icon': '/assets/img/city.svg', price: 9},
            mode: {},
            option: {},
            separateAddress: false
        },
        billing: {
            address: {
                line1: '',
                line2: '',
                city: '',
                region: '',
                country: 'New Zealand',
                postcode: ''
            },
            // option: {'label': 'Bank Transfer', 'value': 'banktransfer', 'icon': '/assets/img/bank.svg'},
            option: {}
        },
        customer: '',
        customerEmail: '',
        costs: $bag.costs,
        orderedDate: 0
    }, ordering = 'notStarted', orderReq = {};

    $: if ($auth?.token) {
        if (progress < 25) {
            progress = 25;
        };
        order.customer = $auth.profile.id;
        order.customerEmail = $auth.email;
    } else {
        progress = 0;
    };

    $: if ($auth?.token) {
        order.billing.address = $auth.profile?.address;
        order.shipping.address = $auth.profile?.address;
    };

    $: if (order.shipping.separateAddress == false) {
        order.shipping.address = order.billing.address;
    };

    $: $bag.costs.delivery = order.shipping.mode.value == 'deliver' ? order.shipping.option.price : 0;

    let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    async function placeOrder() {
        ordering = 'inProgress';

        order.items = $bag.items;
        order.costs = $bag.costs;
        order.orderedDate = dayjs().unix();
        if (order.shipping.separateAddress == false) {
            order.shipping.address = order.billing.address;
        };
        order.nanoid = nanoid();
        order.fulfilment = { value: 'ordered', show: 'Order Placed' };
        order.tracking = { url: '', no: '' };

        // let orderData = await fetch('https://server.therosyshell.co.nz/checkout/calc', {
        orderReq = await fetch('https://server.therosyshell.co.nz/checkout/create', {
            'method': 'POST',
            'headers': {
                'Authorization': 'Ymjb58aay9tKUQhoYg43vgpm9iLg8dV7',
                'Content-Type': 'application/json'
            },
            'body': JSON.stringify(order)
        }).then(res => { return res.json(); });

        // let orderData = await fetch('https://db.therosyshell.co.nz/api/collections/orders/records', {
        //     'method': 'POST',
        //     'headers': {
        //         'Content-Type': 'application/json',
        //         'Authorization': $auth.token
        //     },
        //     'body': JSON.stringify(order)
        // }).then(res => { return res.json() });

        if (orderReq.code == 500) {
            return placeOrder();
        } else if (orderReq.code == 200) {
            $bag = { 'items': [], 'costs': { 'subtotal': 0, 'delivery': 0 } };
            ordering = 'done';
        } else if (orderReq.code == 400) {
            ordering = 'failed-unstocked';
        };
    };
</script>

{#if ordering == 'inProgress'}
<div class="absolute top-0 left-0 z-50 flex flex-col items-center justify-center w-screen h-screen overflow-hidden bg-black bg-opacity-50">
    <div class="rounded-xl flex flex-col items-center justify-center p-16 bg-white shadow-xl">
        <h1>Placing Order...</h1>
        <h2>Sit tight! This should only take a few seconds...</h2>
    </div>
</div>
{/if}

<div class="lg:flex-1 lg:h-full lg:max-h-full lg:overflow-auto rounded-xl flex flex-col items-center justify-start w-full">
    <div id="banner" class="bg-brand flex flex-col items-center justify-center w-full p-3 text-white rounded-t-lg">
        <h1 class="font-brand text-4xl">Checkout</h1>
    </div>
    
    <div id="progress" class="sticky top-0 flex flex-row items-center justify-center w-full py-2 mb-2 font-bold shadow-md transition-[background] ease-in-out bg-white rounded-b-xl duration-400" style="background: linear-gradient(90deg, rgba(251, 207, 232,1) 0%, rgba(251, 207, 232, 1) {progress - 1.125}%, rgba(255,255,255,1) {progress + 1.125}%);">
        <p class="lg:text-xl flex-1 text-center">Account</p>
        <p class="lg:text-xl flex-1 text-center">Delivery</p>
        <p class="lg:text-xl flex-1 text-center">Payment</p>
        <p class="lg:text-xl flex-1 text-center">Confirm</p>
    </div>
    
    {#if $auth?.token}
    <div class="rounded-xl flex flex-col items-center justify-center w-full p-5 mb-5 bg-white">
        <h1>Welcome Back, {$auth.profile.first}!</h1>
        <h2>You're logged in with {$auth.email}</h2>

        <button class="rounded-xl bg-brand w-full px-5 py-2 mt-3 text-white" on:click={() => { $auth = {}; window.location = '/login'; }}>Sign Out</button>
    </div>
    {:else}
    <div class="rounded-xl bg-brand flex flex-col items-center justify-center w-full p-5 mb-5 text-white">
        <h1>Welcome!</h1>
        <h2>Please Log In or Sign Up to continue.</h2>
    </div>

    <div class="rounded-xl flex flex-col items-center justify-center w-full p-5 bg-white">
        <LoginForm></LoginForm>
        <span class="pb-5"></span>
        <p class="line"><span>or</span></p>
        <span class="pt-4"></span>
        <SignupForm></SignupForm>
    </div>
    {/if}

    {#if $bag.items?.length > 0 && ordering != 'done' && ordering != 'failed-unstocked'}
    {#if progress >= 25}
    <div class="rounded-xl bg-brand flex flex-col items-center justify-center w-full p-5 mt-10 mb-5 text-white">
        <h1>Delivery and Shipping</h1>
        <h2>Choose how would you like to receive your order</h2>
    </div>

    <div class="rounded-xl flex flex-col items-center justify-center w-full p-5 mb-5 bg-white">
        <MultipleChoice options={[
            {'label': 'Deliver to my Address', 'value': 'deliver', 'icon': '/assets/img/delivery.svg', 'show': true},
            {'label': 'I\'ll pick it up', 'value': 'pickup', 'icon': '/assets/img/pickup.svg', 'show': true}
        ]} bind:value={order.shipping.mode}></MultipleChoice>
    </div>

    <div class="rounded-xl flex flex-col items-center justify-center w-full p-5 mb-5 bg-white">
        <h1 class="w-full text-xl text-center">Billing Address</h1>
        <AddressForm bind:value={order.billing.address}></AddressForm>

        {#if order.shipping.mode.value != 'pickup'}
            <div class="flex flex-row items-center justify-center select-none">
                <label for="separateShippingBilling" class="px-4 py-2 rounded-lg lg:mr-2 mr-5 aspect-square flex justify-center items-center font-bold cursor-pointer text-white {order.shipping.separateAddress == false ? 'bg-green-500' : 'bg-red-400'}">{order.shipping.separateAddress == false ? '✓' : '✕'}</label>
                <input type="checkbox" id="separateShippingBilling" class="hidden" bind:checked={order.shipping.separateAddress}>
                <label for="separateShippingBilling" class="my-4 text-lg cursor-pointer">My shipping address is the same as my Billing Address</label>
            </div>

            {#if order.shipping.separateAddress == true}
            <h1 class="w-full text-xl text-center">Shipping Address</h1>
            <AddressForm bind:value={order.shipping.address}></AddressForm>
            {/if}
        {/if}
    </div>

    <!-- Delivery Options (Urban, Rural, HBC) -->
    {#if order.shipping.mode.value == 'deliver'}
    <div class="rounded-xl flex flex-col items-center justify-center w-full p-5 mb-5 bg-white">
        <MultipleChoice options={[
            {'label': 'Urban Delivery - $9', 'value': 'urban', 'icon': '/assets/img/city.svg', 'price': 9, 'show': true},
            {'label': 'Rural Delivery - $14', 'value': 'rural', 'icon': '/assets/img/land.svg', 'price': 14, 'show': true},
            {'label': 'Local Delivery - Hibiscus Coast - $4', 'value': 'hbc', 'icon': '/assets/img/home.svg', 'price': 4, 'show': /0932|0931|0930/.test(order.shipping.address.postcode)}
        ]} bind:value={order.shipping.option}></MultipleChoice>
    </div>
    {/if}
        
    {#if progress == 25 && order.shipping.mode?.value && (order.shipping.mode.value == 'pickup' || order.shipping.option?.value)}
    <button class="rounded-xl bg-brand w-full px-5 py-2 mt-3 text-white" on:click={() => progress = 50}>Continue</button>
    {/if}
    {/if}
    
    {#if progress >= 50}
    <div class="rounded-xl bg-brand flex flex-col items-center justify-center w-full p-5 mt-10 mb-5 text-white">
        <h1>Payment</h1>
        <h2>Choose how would you like to pay for your order</h2>
    </div>

    <div class="rounded-xl flex flex-col items-center justify-center w-full p-5 mb-5 bg-white">
        <MultipleChoice options={[
            {'label': 'Bank Transfer', 'value': 'banktransfer', 'icon': '/assets/img/bank.svg', 'show': true},
            {'label': 'Credit or Debit Card', 'value': 'card', 'icon': '/assets/img/card.svg', 'show': true}
        ]} bind:value={order.billing.option}></MultipleChoice>
    </div>

    {#if order.billing.option.value}
    <div class="rounded-xl flex flex-col items-center justify-center w-full p-5 mb-5 bg-white">
        {#if order.billing.option.value == 'banktransfer'}
        <h2>Payment should be made to 'The Rosy Shell', 38-9015-0182370-00. Please use your Order Number as a reference.</h2>
        {:else if order.billing.option.value == 'card'}
        <h2>An email will be sent to {$auth.email} shortly to arrange payment.</h2>
        {/if}
        
        {#if progress == 50 && order.billing.option?.value}
        <button class="rounded-xl bg-brand w-full px-5 py-2 mt-3 text-white" on:click={() => progress = 75}>Continue</button>
        {/if}
    </div>
    {/if}
    {/if}
    
    {#if progress >= 75}
    <div class="rounded-xl bg-brand flex flex-col items-center justify-center w-full p-5 mt-10 mb-5 text-white">
        <h1>Confirm Order</h1>
        <h2>Please check your bag contents {width >= 1024 ? 'on the left' : 'at the top of the page'} and confirm your selections.</h2>
    </div>

    <div class="rounded-xl flex flex-col items-center justify-center w-full p-5 mb-5 bg-white">
        {#if order.shipping.mode.value == 'deliver'}
        <h2>We will ship your order to this address:</h2>
        <p>{order.shipping.address.line1}</p>
        <p>{order.shipping.address.line2}</p>
        <p>{order.shipping.address.city}, {order.shipping.address.region}</p>
        <p>{order.shipping.address.country}, {order.shipping.address.postcode}</p>
        {:else if order.shipping.mode.value == 'pickup'}
        <h2>You will be picking up your order.</h2>
        <h2>An email will be sent to {$auth.email} within the next 1-4 working days about where to pickup.</h2>
        {/if}

        <h2 class="mt-5">You have chosen to pay by {order.billing.option.value == 'banktransfer' ? 'Bank Transfer' : 'Credit or Debit Card'}.</h2>
        {#if order.billing.option.value == 'banktransfer'}
        <p>Payment should be made to 'The Rosy Shell', 38-9015-0182370-00. Please use your Order Number as a reference.</p>
        {:else if order.billing.option.value == 'card'}
        <p>An email will be sent to {$auth.email} shortly to arrange payment.</p>
        {/if}

        <h2 class="mt-5">Is this all correct? If so, you can place your order now!</h2>
        <button class="rounded-xl bg-brand w-full px-5 py-2 mt-2 text-white" on:click={() => { progress = 105; placeOrder(); }}>Place Order</button>
    </div>
    {/if}
    {:else if ordering == 'done'}
    <div class="rounded-xl flex flex-col items-center justify-center w-full p-5 mt-10 bg-white">
        <h1>Order Placed! 🎉</h1>
        <h2>Your order has successfully been placed!</h2>
        <h2>Your Order ID is {order.nanoid}</h2>

        <h2 class="mt-5">You can visit your account page to track your order status at any time.</h2>
        <h1>Thanks for shopping!</h1>

        <button class="rounded-xl bg-brand w-full px-5 py-2 mt-3 text-center text-white" on:click={() => navigateTo('/account')}>View My Orders</button>
    </div>
    {:else if ordering == 'failed-unstocked'}
    <div class="rounded-xl flex flex-col items-center justify-center w-full p-5 mt-10 bg-white">
        <h1>Order Failed!</h1>
        <h2>Unfortunately, one or more items from your order are now unavailable. Sorry!</h2>

        <h2 class="mt-5">Unavailable Items:</h2>
        {#each orderReq.details.unstocked as item}
            <p>{item.name}{item.variant?.id ? ': ' + item.variant.label + ' - Out of Stock' : ' - You ordered ' + item.quantity + ', we only have ' + item.stock + '.'}</p>
        {/each}

        <h2 class="mt-5">Please remove these items and try again.</h2>
        <h1>Thanks for shopping!</h1>

        <button class="rounded-xl bg-brand w-full px-5 py-2 mt-3 text-center text-white" on:click={() => window.location = window.location}>Try Again</button>
    </div>
    {:else}
    <div class="rounded-xl flex flex-col items-center justify-center w-full p-5 mt-10 bg-white">
        <h1>Uh oh! Looks like your bag is empty!</h1>
        <h2>Please add an item to your bag to checkout.</h2>

        <button class="rounded-xl bg-brand w-full px-5 py-2 mt-3 text-center text-white" on:click={() => navigateTo('/')}>Continue Shopping</button>
    </div>
    {/if}
</div>

<style>
    h1:not(#banner h1) {
        @apply w-full mb-2 text-2xl text-center;
    }
    
    h2 {
        @apply w-full text-xl text-center;
    }
    
    p:not(#progress p) {
        @apply w-full text-lg text-center;
    }
    
    button:not(.bg-brand) {
        text-decoration: underline;
    }

    p.line {
        width: 100%; 
        text-align: center; 
        border-bottom: 1px solid #000; 
        line-height: 0.1em;
        margin: 10px 0 20px; 
    } 

    p.line span { 
        background:#fff; 
        padding: 0 10px; 
    }
</style>

