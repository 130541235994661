<script>
	import { auth } from '../lib/data.js';
    import { navigateTo } from 'svelte-router-spa';

    let btn, email = '', password = '', err = { show: false, message: '' };
    
    async function logIn() {
        btn.disabled = true;

        if (email.length < 1 || password.length < 1) {
            err = { show: true, message: 'Invalid Email or Password' };
            return btn.disabled = false;
        } else if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email) != true) {
            err = { show: true, message: 'Invalid Email' };
            return btn.disabled = false;
        } else {
            err.show = false;
        };
        
        try {
            let user = await fetch('https://db.therosyshell.co.nz/api/users/auth-via-email', {
                'method': 'POST',
                'headers': {
                    'Content-Type': 'application/json'
                },
                'body': JSON.stringify({
                    "email": email,
                    "password": password
                })
            }).then(res => { return res.json() });

            if (!user.token) throw user;

            $auth = user.user;
            $auth.token = user.token;

            email = '', password = '';
        } catch (error) {
            err = { show: true, message: error.message };
            return btn.disabled = false;
        };
    };
</script>

<p>Email Address</p>
<input placeholder="john.doe@email.tld" bind:value={email} name="email" on:keydown={e => { if (e.key == 'Enter') logIn(); }}>
<div class="flex flex-row items-center justify-between w-full">
    <p>Password</p>
    <a on:click={() => navigateTo('/')} class="text-right">Forgot Password?</a>
</div>
<input placeholder="*********" type="password" bind:value={password} name="password" on:keydown={e => { if (e.key == 'Enter') logIn(); }}>
<button class="rounded-xl bg-brand w-full px-5 py-2 text-white" on:click={() => logIn()} bind:this={btn}>Log In</button>
{#if err.show == true}
<p class="text-brand pt-1 font-bold">{err.message}</p>
{/if}

<style>
    p {
        @apply w-full text-left mb-1;
    }
    a {
        @apply w-full mb-1 text-brand underline;
    }
    input {
        @apply p-3 rounded-xl overflow-hidden bg-slate-200 transition-shadow duration-200 ease-in-out w-full mb-3 placeholder:text-gray-600;
    }
    input:focus, input:active {
        @apply ring-2 ring-pink-300;
    }
</style>

