<svelte:head>
    <title>The Rosy Shell</title>
    <script defer data-domain="therosyshell.co.nz" src="https://analytics.izmichael.com/js/plausible.js"></script>

    <!-- Primary Meta Tags -->
    <title>The Rosy Shell</title>
    <meta name="title" content="The Rosy Shell">
    <meta name="description" content="Based on the beautiful Hibiscus Coast, The Rosy Shell creates beautiful home-sewn accessories for children, adults, and around the home. Bucket hats, handbags, playing card holders, glasses cases, makeup bags, serviette holders, bunting, baskets, facemasks, and many more items available!">

    <!-- Open Graph / Facebook -->
    <meta property="og:type" content="website">
    <meta property="og:url" content="https://therosyshell.co.nz/">
    <meta property="og:title" content="The Rosy Shell">
    <meta property="og:description" content="Based on the beautiful Hibiscus Coast, The Rosy Shell creates beautiful home-sewn accessories for children, adults, and around the home. Bucket hats, handbags, playing card holders, glasses cases, makeup bags, serviette holders, bunting, baskets, facemasks, and many more items available!">
    <meta property="og:image" content="https://therosyshell.co.nz/assets/img/seo-banner.png">

    <!-- Twitter -->
    <meta property="twitter:card" content="summary_large_image">
    <meta property="twitter:url" content="https://therosyshell.co.nz/">
    <meta property="twitter:title" content="The Rosy Shell">
    <meta property="twitter:description" content="Based on the beautiful Hibiscus Coast, The Rosy Shell creates beautiful home-sewn accessories for children, adults, and around the home. Bucket hats, handbags, playing card holders, glasses cases, makeup bags, serviette holders, bunting, baskets, facemasks, and many more items available!">
    <meta property="twitter:image" content="https://therosyshell.co.nz/assets/img/seo-banner.png">
</svelte:head>

<script>
    import { Router } from 'svelte-router-spa'
    import { routes } from './routes';
    import { onMount } from 'svelte';
    
    import Drawer from 'svelte-drawer-component';
    import Sidebar from './lib/Sidebar.svelte';
	import { auth } from './lib/data.js';
	import { getCountry } from './lib/location.js';

    onMount(async () => {
        if ($auth?.token) {
            let user = await fetch('https://db.therosyshell.co.nz/api/users/refresh', {
                'method': 'POST',
                'headers': {
                    'Authorization': 'User ' + $auth.token
                }
            }).then(res => { return res.json() });

            $auth = user.user;
            $auth.token = user.token;
        };

        notNZ = (getCountry() != 'New Zealand');
    });

    let notNZ = false;

    let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let drawerShow = false;

    var loadPoly = false
    if (!self.fetch) {loadPoly = true}
    if (!loadPoly && !self.Headers.prototype.entries) {loadPoly = true}
    if (!loadPoly && !!self.Headers.prototype.entries.prototype) {loadPoly = true}
    if (!loadPoly) {
        self.fetch||(self.fetch=function(e,n){return n=n||{},new Promise(function(t,s){var r=new XMLHttpRequest,o=[],u=[],i={},a=function(){return{ok:2==(r.status/100|0),statusText:r.statusText,status:r.status,url:r.responseURL,text:function(){return Promise.resolve(r.responseText)},json:function(){return Promise.resolve(JSON.parse(r.responseText))},blob:function(){return Promise.resolve(new Blob([r.response]))},clone:a,headers:{keys:function(){return o},entries:function(){return u},get:function(e){return i[e.toLowerCase()]},has:function(e){return e.toLowerCase()in i}}}};for(var c in r.open(n.method||"get",e,!0),r.onload=function(){r.getAllResponseHeaders().replace(/^(.*?):[^\S\n]*([\s\S]*?)$/gm,function(e,n,t){o.push(n=n.toLowerCase()),u.push([n,t]),i[n]=i[n]?i[n]+","+t:t}),t(a())},r.onerror=s,r.withCredentials="include"==n.credentials,n.headers)r.setRequestHeader(c,n.headers[c]);r.send(n.body||null)})});
    };
</script>

<div class="lg:flex-row font-clean bg-slate-100 lg:max-h-screen overscroll-none lg:h-screen min-h-screen flex flex-col items-center justify-center w-full" on:resize={() => width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth}>
    <div class="lg:hidden rounded-b-xl bg-brand flex flex-row items-center justify-center w-full overflow-hidden text-white sticky top-0 z-[1000]">
        <button on:click={() => drawerShow = true}><img src="/assets/img/hamburger.svg" class="aspect-square h-16 p-3" alt="Hamburger Menu Icon"></button>
        <h1 class="font-brand flex-1 p-3 text-4xl text-center">The Rosy Shell</h1>
        <img src="/assets/img/hamburger.svg" class="aspect-square invisible h-16 p-3" alt="Hamburger Menu Icon">
    </div>
    <Sidebar></Sidebar>
    
    <div class="shrink-0 lg:my-0 lg:p-10 overscroll-none lg:max-h-full lg:overflow-y-scroll flex flex-col items-center justify-start flex-1 w-full lg:w-[unset] h-full p-5 overflow-x-hidden">
        <Router {routes} />
    </div>
</div>

<Drawer open={drawerShow} on:clickAway={() => drawerShow = false} size="80%">
    <Sidebar drawer="true" bind:drawerShow></Sidebar>
</Drawer>

{#if notNZ == true}
    <div class="w-full h-screen bg-black/50 flex flex-col justify-center items-center z-[10000] absolute inset-0">
        <div class="lg:w-1/2 w-10/12 bg-white rounded-xl p-10 flex flex-col justify-center items-center">
            <div class="flex flex-row justify-center items-center mb-5">
                <img src="/favicon.png" class="w-16 mr-3 rounded-full aspect-square" alt="The Rosy Shell Logo" />
                <h1 class="text-4xl font-bold text-center w-full">The Rosy Shell</h1>
            </div>

            <h2 class="text-2xl font-bold text-center w-full mb-2">Welcome! It looks like you're not in NZ.</h2>
            <h3 class="text-xl text-center w-full mb-5">Unfortunately, we can't sell to people outside of New Zealand. You can still browse our site, but you won't be able to buy anything. Sorry!</h3>

            <button class="rounded-xl bg-brand py-2 mt-3 text-center text-white font-bold px-10 min-w-[50%]" on:click={() => notNZ = false }>Continue to Site</button>
        </div>
    </div>
{/if}

<style global>
    @tailwind base;
    @tailwind components;
    @tailwind utilities;

    * {
        outline: none;
    }

    /* Scrollbar */
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f100; 
    }
    ::-webkit-scrollbar-thumb {
        background: #888; 
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
</style>